import logo from './logo.svg';
import './App.css';

const links = [
  {
    title: 'Otodom parter',
    link: 'https://www.otodom.pl/pl/oferty/sprzedaz/mieszkanie/wejherowo?roomsNumber=%5BONE%2CTWO%5D&floors=%5BGROUND%5D&limit=72&page=1'
  },
  {
    title: 'Otodom z windą',
    link: 'https://www.otodom.pl/pl/oferty/sprzedaz/mieszkanie/wejherowo?distanceRadius=0&page=1&limit=72&market=ALL&roomsNumber=%5BONE%2CTWO%5D&locations=%5Bcities_6-2042%5D&extras=%5BLIFT%5D&viewType=listing'
  },
  {
    title: 'Trojmiasto parter',
    link: 'https://ogloszenia.trojmiasto.pl/nieruchomosci/mieszkanie/wejherowo/ikl,101_106,ri,1_2,si,-1_-1.html'
  },
  {
    title: 'Trojmiasto z windą',
    link: 'https://ogloszenia.trojmiasto.pl/nieruchomosci/mieszkanie/wejherowo/ii,1,ikl,101_106,ri,1_2.html'
  },
  {
    title: 'Morizon parter',
    link: 'https://www.morizon.pl/mieszkania/wejherowo/?ps%5Bnumber_of_rooms_from%5D=1&ps%5Bnumber_of_rooms_to%5D=2&ps%5Bfloor_from%5D=0&ps%5Bfloor_to%5D=0'
  },
  {
    title: 'Morizon z windą',
    link: 'https://www.morizon.pl/mieszkania/wejherowo/?ps%5Bnumber_of_rooms_from%5D=1&ps%5Bnumber_of_rooms_to%5D=2&ps%5Bhas_elevator%5D=1'
  },
  {
    title: 'nieruchomosci-online parter',
    link: 'https://wejherowo.nieruchomosci-online.pl/szukaj.html?3,mieszkanie,sprzedaz,,Wejherowo:28951,,,,,,,,,,,1-2,,,,,,,0-0'
  },
  {
    title: 'nieruchomosci-online z windą',
    link: 'https://wejherowo.nieruchomosci-online.pl/szukaj.html?3,mieszkanie,sprzedaz,,Wejherowo:28951,,,,,,,,,,,1-2,,,,,,1'
  },
  {
    title: 'domiporta parter',
    link: 'https://www.domiporta.pl/mieszkanie/sprzedam/pomorskie/wejherowo?Rooms.From=1&Rooms.To=2&Pietro.From=0&Pietro.To=0'
  },
  {
    title: 'domiporta z windą',
    link: 'https://www.domiporta.pl/mieszkanie/sprzedam/pomorskie/wejherowo?Rooms.From=1&Rooms.To=2&EstateFacilities=winda.253'
  }
]

function App() {
  return (
    <div className="App">
      <h3>Lista linków do mieszkań</h3>
      <ul>
        {links.map(link => (
          <li key={link.title}>
            <a href={link.link} target="_blank" rel="noreferrer">{link.title}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;
